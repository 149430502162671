<template>
  <div
    :class="['title', `${color}--text`, ellipsis ? 'ellipsis' : '', bold ? 'font-weight-bold' : '']"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      default: "primary",
    },
    ellipsis: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>